export default function Formatter(initialDate: any, isUTC: boolean = false) {
    if (isUTC) {
        let dateTime = initialDate.split('.')[0].split('T') as string[]
        let date = dateTime[0].split('-')
        let time = dateTime[1].split(':')
        time.pop()
        
        let month = ''
        let day = date[2]
        let year = date[0]

        let minutes = time[1]
        let hour = ''
        let ampm = ''
        if(Number(time[0]) > 12) {
            hour = (Number(time[0]) - 12).toString().padStart(2, '0')
            ampm = 'PM'
        } else {
            hour = time[0]
            ampm = 'AM'
        }

        switch (date[1]) {
            case '01': month = 'Jan'; break;
            case '02': month = 'Feb'; break;
            case '03': month = 'Mar'; break;
            case '04': month = 'Apr'; break;
            case '05': month = 'May'; break;
            case '06': month = 'Jun'; break;
            case '07': month = 'Jul'; break;
            case '08': month = 'Aug'; break;
            case '09': month = 'Sep'; break;
            case '10': month = 'Oct'; break;
            case '11': month = 'Nov'; break;
            case '12': month = 'Dec'; break;
        }

        return `${month} ${day} ${year} ${hour}:${minutes}${ampm}`

    } else {
        let time = initialDate.split(" ").pop()
        let createdHour = time.split(":")[0]
        let createdMinutes = time.split(":")[1]
        let day = initialDate.split(" ").splice(0, 3).join(" ")
        if (createdHour === "00") {
            return `${day} ${12}:${createdMinutes}AM`
        }
        if (createdHour === "12") {
            return `${day} ${12}:${createdMinutes}PM`
        }
        if (createdHour >= 12) {
            return `${day} ${createdHour - 12}:${createdMinutes}PM`
        }
        else {
            return `${day} ${createdHour}:${createdMinutes}AM`
        }
    }
}