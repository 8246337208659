import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FiHome, FiPlay, FiCreditCard, FiAlertCircle, FiSettings, FiPlusCircle } from 'react-icons/fi';

import Loginbutton from '../LoginButton';

const SideBar = () => {
    const [logged, setLogged] = useState(false)
    const [actived, setActived] = useState(window.location.pathname)
    useEffect(() => {
        if (localStorage.getItem('token')) {
            setLogged(true)
        } else {
            setLogged(false)
        }

        if (["/", "/cardtickets", "/gametickets"].includes(window.location.pathname)) {
            return
        } if (["/cardtickets/new", "/gametickets/new"].includes(window.location.pathname)) {
            setActived(window.location.pathname)
        }
    }, [localStorage.getItem('token')])
    return (
        <div className="sidebar">
            <img src="/assets/Monograma.png" alt="FEG Logo" id="monograma" />
            <Link
                onClick={() => setActived("/")}
                to="/"
                className={(actived === "/") ? "active" : ""}
                title="Home"
            >
                <FiHome size={20} />
            </Link>
            {/* <Link onClick={() => setActived("/cardtickets")} to="/cardtickets" className={(["/cardtickets", "/cardtickets/new"].includes(actived)) ? "active" : ""} title="Card Tickets"><FiCreditCard size={20} /></Link> */}
            {/* <Link onClick={() => setActived("/gametickets")} to="/gametickets" className={(["/gametickets", "/gametickets/new"].includes(actived)) ? "active" : ""} title="Game Tickets"><FiPlay size={20} /></Link> */}
            <Link
                style={{display: logged ? 'block' : 'none'}}
                onClick={() => setActived("/emergency")}
                to="/emergency"
                className={(actived === "/emergency") ? "active" : ""}
                title="Emergency Tickets"
            >
                    <FiAlertCircle size={20} />
            </Link>
            {/* ---------------------- */}
            <Link
                onClick={() => setActived("/emergency/new")}
                to="/emergency/new"
                className={(actived === "/emergency/new") ? "active" : ""}
                title="New Emergency Tickets"
            >
                    <FiPlusCircle size={20} />
            </Link>
            {/* ----------------------------- */}
            <Link
                style={{display: logged ? 'block' : 'none'}}
                onClick={() => setActived("/settings")}
                to="/settings"
                className={(actived === "/settings") ? "active" : ""}
                title="Settings"
            >
                <FiSettings size={20} />
            </Link>
            <div id="config">
                <Loginbutton color="#f5f5f5" size={20} title="Configuration" width='100%' />
            </div>
        </div>
    )
}

export default SideBar;
