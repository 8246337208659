import React from 'react';
import LoginButton from '../LoginButton';
const Header = () => {
    return (
        <header>
            <div className="upper">
            </div>
            <div className="icons">
                <LoginButton color="#7F43F5" width='20%' />
            </div>
        </header>
    )
}

export default Header