import React, { useState } from 'react';
import Loader from '../../Loader';

const Content = (props: any) => {

  const [loaded, setLoaded] = useState(true);

  return (
    <div className="box-content images">
      {
        (loaded ? <Loader /> : <></>)
      }
      <img src={`${props.ImageName}`} onLoad={() => setLoaded(false)} alt="PowerBI" width={100} height={100} />
      <h1>{props.Total}</h1>
      <h2>{props.Content}</h2>
      {/* <button>OPEN</button> */}
    </div>
  );
}

export default Content;
