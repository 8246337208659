import React, { useEffect, useState } from 'react';
import axios from '../../../../services/axios';
import Select from 'react-select';
import { locationsData, systemTypes } from '../../../../data';
import TextField from '@material-ui/core/TextField';
import MuiPhoneNumber from 'material-ui-phone-number';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha'

const profile_id = localStorage.getItem('id');
const profile_name = `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`;
const requester_image = localStorage.getItem('image_url');

interface imageFormat {
    selectedFile: "",
    type: "",
}

interface LocationOption {
    value: string
    label: string
}

interface imageArray extends Array<imageFormat> { }


const EmergencyTicket: React.FC = () => {
    const history = useHistory()
    const [subject, setSubject] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [system, setSystem] = useState<any>([]);
    const [date, setDate] = useState<string>(new Date().toISOString().split("T")[0]);
    const [locations, setLocation] = useState<any>([]);
    const [email, setEmail] = useState<string>(localStorage.getItem('id') ? localStorage.getItem('email')! : '')
    const [phone_number, setPhone] = useState<string>();
    const [firstName, setFirstName] = useState<string>(localStorage.getItem('id') ? localStorage.getItem('firstName')! : '')
    const [lastName, setLastName] = useState<string>(localStorage.getItem('id') ? localStorage.getItem('lastName')! : '')
    const [imageFile, setImageFile] = useState<imageArray>()
    const [captcha, setCaptcha] = useState<string | null>(null)
    const data_needed = new Date().toISOString().split("T")[0];
    const [locationsData2, setLocationData] = useState<LocationOption[]>([])

    function validateEmail(email: any) {
        let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return res.test(email)
    }

    async function loadLocationData() {
        const locationData: LocationOption[] = []
        await axios.get('/erp2/locations')
            .then(data => {
                data.data.forEach((location: any) => {
                    locationData.push({
                        value: location.id,
                        label: location.location_name
                    })
                })
            })
            .catch(error => console.log(error))

        if (locationData) setLocationData(locationData)
    }

    useEffect(() => {
        loadLocationData()
    }, [])

    async function createTicket() {
        const created_at = new Date().toString().split("").splice(4, 17).join("");

        if (locations.length < 1) {
            return alert("Locations cannot be null")
        }
        if (!subject) {
            return alert("Subject cannot be null")
        }
        if (!description) {
            return alert("Description cannot be null")
        }
        if (system.length < 1) {
            return alert("System cannot be null")
        }
        if (!email) {
            return alert("Email cannot be null")
        } else if (!validateEmail(email)) {
            return alert("Invalid email format")
        }
        if (!phone_number) {
            return alert("Phone Number cannot be null")
        }
        if (!firstName.trim() || !lastName.trim()) {
            return alert("First Name and Last Name cannot be null")
        }
        if (!captcha) {
            return alert("ReCAPTCHA cannot be null")
        }

        const dataSend = ({
            profile_id: profile_id!,
            location_id: [locations],
            subject,
            description,
            system,
            data_needed,
            email,
            phone_number,
            requester_name: localStorage.getItem('id') ? 
                `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`
                : `${firstName} ${lastName}`,
            requester_image,
            images: imageFile,
            created_at,
        })

        try {
            // console.log(dataSend);
            await axios.post('emergency', dataSend).then(data => {
                // console.log('1» ', data.data.emails)
                // alert("Ticket Created! All of these emails below would receive a notification:\n" + data.data.emails.join("\n"))
                alert("Ticket Created!")
            })
            if (profile_id) {
                history.push('/emergency')
            } else {
                history.push('/emergency/new/success')
            }
        } catch (error) {
            alert(error)
        }
    }

    function fileSelect(props: any) {
        function multiImages(props: any, size: any) {
            const reader = new FileReader()
            reader.readAsDataURL(props[size])
            reader.onload = (e: any) => {
                setImageFile((prevState) => [
                    ...prevState!,
                    {
                        selectedFile: e?.target?.result,
                        type: props[size].type
                    }
                ])
            }
        }

        const reader = new FileReader();
        reader.readAsDataURL(props[0])
        reader.onload = (e: any) => {
            setImageFile([
                {
                    selectedFile: e?.target?.result,
                    type: props[0].type
                },
            ])
        }
        setTimeout(() => {
            for (let i = 1; i < props.length; i++) {
                multiImages(props, i);
            }
        }, 500)
    }

    return (
        <div className="emergency-ticket">
            <h2>New Ticket</h2>
            <div className="utils">
                <h4>Location</h4>
                <Select
                    id="demo-simple-select"
                    name="colors"
                    options={locationsData2}
                    className="basic-select"
                    classNamePrefix="select"
                    onChange={e => setLocation(e)}
                    // value={locations}
                    placeholder="Locations Here"
                />

                <h4>Subject</h4>
                <input
                    type="text"
                    name="nameInput"
                    value={subject}
                    maxLength={250}
                    onChange={e => setSubject(e.target.value)}
                />

                <h4>Description</h4>
                <textarea
                    id="w3review"
                    name="w3review"
                    rows={4}
                    cols={50}
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />

                <h4>System</h4>
                <Select
                    id="demo-simple-select"
                    name="colors"
                    options={systemTypes}
                    className="basic-select"
                    classNamePrefix="select"
                    onChange={e => setSystem(e!.value)}
                    placeholder="System Here"
                />

                {/* <h4>Date</h4>
                <TextField
                    id="datetime-start"
                    // label="Start time"
                    type="date"
                    value={date}
                    onChange={e => {
                        setDate(e.target.value)
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                /> */}

                <h4>Best Email to Contact Me</h4>
                <input
                    type="text"
                    name="emailInput"
                    value={localStorage.getItem('id') ? localStorage.getItem('email')! : email}
                    maxLength={100}
                    style={!!localStorage.getItem('id') ? {backgroundColor: 'lightgrey'} : {}}
                    onChange={e => setEmail(e.target.value)}
                    disabled={!!localStorage.getItem('id')}
                />
                <h4>Best Phone Number to Contact Me</h4>
                <MuiPhoneNumber defaultCountry={'us'} onChange={e => setPhone(e)} />

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '40%'}}>
                    <div>
                        <h4>First Name</h4>
                        <input 
                            type="text"
                            name="firstName"
                            value={localStorage.getItem('id') ? localStorage.getItem('firstName')! : firstName}
                            maxLength={50}
                            style={!!localStorage.getItem('id') ? {width: 150, backgroundColor: 'lightgrey'} : {width: 150}}
                            onChange={e => setFirstName(e.target.value)}
                            disabled={!!localStorage.getItem('id')}
                        />
                    </div>
                    
                    <div>
                        <h4>Last Name</h4>
                        <input 
                            type="text"
                            name="lastName"
                            value={localStorage.getItem('id') ? localStorage.getItem('lastName')! : lastName}
                            maxLength={50}
                            style={!!localStorage.getItem('id') ? {width: 150, backgroundColor: 'lightgrey'} : {width: 150}}
                            onChange={e => setLastName(e.target.value)}
                            disabled={!!localStorage.getItem('id')}
                        />
                    </div>
                </div>

                <div className="col-6 mt-4">
                    <div className="form-group">
                        <h4>Select Multiple Files</h4>
                        <input type="file" onChange={(e) => fileSelect(e.target.files)} className="form-control" accept=".jpg,.png" multiple />
                    </div>
                </div>

                <div className="mt-3 mb-3">
                    <ReCAPTCHA
                        sitekey={'6LccDoEjAAAAALcbnUu7hVcd6gODWgBjKHGwrBQQ'}
                        onChange={setCaptcha}
                    />
                    <button className="btn btn-danger mt-3" onClick={createTicket} disabled={!captcha}>Create Emergency Ticket</button>
                </div>

                <Link to=""><h5>Click here to understand how FEG handles Emergency Tickets</h5></Link>
            </div>
        </div>
    );
}

export default EmergencyTicket;