import React, { KeyboardEventHandler, useEffect, useState } from 'react';

import axios from '../../../services/axios';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Loader from '../../Loader';

interface UserERP {
    id: number
    first_name: string
    last_name: string
    email: string
}

interface GroupERP {
    group_id: number
    name: string
}

interface Settings {
    groups_with_email: number[]
    groups_with_first_email: number[]
    groups_with_permission: number[]
    users_with_email: number[]
    users_with_first_email: number[]
    users_with_permission: number[]
    numbers_call: string[]
    numbers_sms: string[]
}

export default function GameSettings() {
    interface Groups {
        value: number;
        label: string;
    }

    interface Users {
        id: number;
        first_name: string;
        last_name: string;
        full_name: string;
        email: string;
        has_all_locations: number;
        group_name: string;
        location_name: number;
        permission_emergency: boolean;
        value: number;
        label: string;
    }

    // const [loading, setLoading] = useState<boolean>(true);
    // const [groupsData, setGroupsData] = useState<Array<Groups>>([]);
    // const [usersData, setUsersData] = useState<Array<Users>>([]);

    // const [selectedGroups, setSelectedGroups] = useState<Array<Groups>>([]);
    // const [selectedUsers, setSelectedUsers] = useState<Array<Users>>([]);

    // const [selectedGroupsEmailEmergency, setSelectedGroupsEmailEmergency] = useState<Array<Groups>>([]);
    // const [selectedUsersEmailEmergency, setSelectedUsersEmailEmergency] = useState<Array<Users>>([]);

    // const [selectedGroupsFirstEmailEmergency, setSelectedGroupsFirstEmailEmergency] = useState<Array<Groups>>([]);
    // const [selectedUsersFirstEmailEmergency, setSelectedUsersFirstEmailEmergency] = useState<Array<Users>>([]);
    
    // new variables for ESR
    const [groupsData2, setGroupsData2] = useState<Array<Groups>>([])
    const [usersData2, setUsersData2] = useState<Array<Users>>([])
    const [selectedGroups2, setSelectedGroups2] = useState<Array<Groups>>([])
    const [selectedUsers2, setSelectedUsers2] = useState<Array<Users>>([])
    const [selectedGroupsEmailEmergency2, setSelectedGroupsEmailEmergency2] = useState<Array<Groups>>([])
    const [selectedUsersEmailEmergency2, setSelectedUsersEmailEmergency2] = useState<Array<Users>>([])
    const [selectedGroupsFirstEmailEmergency2, setSelectedGroupsFirstEmailEmergency2] = useState<Array<Groups>>([])
    const [selectedUsersFirstEmailEmergency2, setSelectedUsersFirstEmailEmergency2] = useState<Array<Users>>([])
    const [inputPhoneCall, setInputPhoneCall] = useState('')
    const [phoneCalls, setPhoneCalls] = useState<readonly {label: string, value: string}[]>([])
    const [inputPhoneSMS, setInputPhoneSMS] = useState('')
    const [phoneSMS, setPhoneSMS] = useState<readonly {label: string, value: string}[]>([])
    const [groupLoaded, setGroupLoaded] = useState(false)
    const [userLoaded, setUserLoaded] = useState(false)
    const loaded = groupLoaded && userLoaded

    async function loadGroupData() {
        setGroupLoaded(false)
        const groups2: any = [];
        const data2 = await axios.get('erp2/groups')
            .then(data => data.data as GroupERP[])
            .catch(error => alert('Error on load groups from ERP.'))

        if (data2) {
            data2.forEach((group: GroupERP) => {
                groups2.push({
                    value: group.group_id,
                    label: group.name
                })
            })
            setGroupsData2(groups2)

            // load the selected groups
            const config: Settings = await axios.get('/erp2/emergency/settings').then(data => data.data[0])
            const initialGroupPermission = groups2.filter((group: any) => config.groups_with_permission.includes(group.value))
            const initialGroupEmail = groups2.filter((group: any) => config.groups_with_email.includes(group.value))
            const initialGroupFirstEmail = groups2.filter((group: any) => config.groups_with_first_email.includes(group.value))
            setSelectedGroups2(initialGroupPermission)
            setSelectedGroupsEmailEmergency2(initialGroupEmail)
            setSelectedGroupsFirstEmailEmergency2(initialGroupFirstEmail)
        } else {
            setGroupsData2([])
        }

        setGroupLoaded(true)
    }

    async function loadUserData() {
        setUserLoaded(false)
        const users2: any = []
        const data2 = await axios.get('/erp2/users')
            .then(data => data.data as UserERP[])
            .catch(error => alert('Error on load users from ERP.'))
        if (data2) {
            data2.forEach(user => {
                users2.push({
                    value: user.id,
                    label: `${user.first_name} ${user.last_name} | ${user.email}`
                })
            })
            setUsersData2(users2)

            // load the selected users
            const config: Settings = await axios.get('/erp2/emergency/settings').then(data => data.data[0])
            const initialUserPermission = users2.filter((user: any) => config.users_with_permission.includes(user.value))
            const initialUserEmail = users2.filter((user: any) => config.users_with_email.includes(user.value))
            const initialUserFirstEmail = users2.filter((user: any) => config.users_with_first_email.includes(user.value))
            setSelectedUsers2(initialUserPermission)
            setSelectedUsersEmailEmergency2(initialUserEmail)
            setSelectedUsersFirstEmailEmergency2(initialUserFirstEmail)

            let numbersCall: any = []
            let numbersSMS: any = []
            config.numbers_call.forEach(number => {
                numbersCall.push({
                    value: number,
                    label: number
                })
            })
            config.numbers_sms.forEach(number => {
                numbersSMS.push({
                    value: number,
                    label: number
                })
            })
            setPhoneCalls(numbersCall)
            setPhoneSMS(numbersSMS)
        } else {
            setUsersData2([])
        }

        setUserLoaded(true)
    }

    // const initialFetchGroupsAndUsers = async () => {
    //     try {
    //         setLoading(true);
    //         const groups: any = [];
    //         const { data } = await axios.get('/erp/groups');
    //         await data.forEach((group: any) => {
    //             groups.push({
    //                 ...group,
    //                 value: group.id,
    //                 label: group.group_name
    //             })
    //         });
    //         setGroupsData(groups);

    //         const groupsWithPermission: any = [];
    //         const groupsWithEmail: any = [];
    //         const groupsWithFirstEmail: any = [];
    //         await data.forEach((group: any) => {
    //             if (group.permission_emergency === true) {
    //                 groupsWithPermission.push({
    //                     ...group,
    //                     value: group.id,
    //                     label: group.group_name
    //                 })
    //             }
    //             if (group.emails_emergency === true) {
    //                 groupsWithEmail.push({
    //                     ...group,
    //                     value: group.id,
    //                     label: group.group_name
    //                 })
    //             }
    //             if (group.first_email_emergency === true) {
    //                 groupsWithFirstEmail.push({
    //                     ...group,
    //                     value: group.id,
    //                     label: group.group_name
    //                 })
    //             }
    //         });
    //         setSelectedGroups(groupsWithPermission);
    //         setSelectedGroupsEmailEmergency(groupsWithEmail);
    //         setSelectedGroupsFirstEmailEmergency(groupsWithFirstEmail);

    //         const userResults = await axios.get('/erp/users?permission_emergency=true&limit=50');
    //         const usersWithPermission: any = [];
    //         await userResults.data.forEach((user: any) => {
    //             usersWithPermission.push({
    //                 ...user,
    //                 value: user.id,
    //                 label: `${user.first_name} ${user.last_name} | ${user.email}`
    //             })
    //         })
    //         setSelectedUsers(usersWithPermission);

    //         const userEmailResults = await axios.get('/erp/users?emails_emergency=true&limit=50');
    //         const usersWithEmail: any = [];
    //         await userEmailResults.data.forEach((user: any) => {
    //             usersWithEmail.push({
    //                 ...user,
    //                 value: user.id,
    //                 label: `${user.first_name} ${user.last_name} | ${user.email}`
    //             })
    //         })
    //         setSelectedUsersEmailEmergency(usersWithEmail);

    //         const userFirstEmailResults = await axios.get('/erp/users?first_email_emergency=true&limit=50');
    //         const usersWithFirstEmail: any = [];
    //         await userFirstEmailResults.data.forEach((user: any) => {
    //             usersWithFirstEmail.push({
    //                 ...user,
    //                 value: user.id,
    //                 label: `${user.first_name} ${user.last_name} | ${user.email}`
    //             })
    //         })
    //         setSelectedUsersFirstEmailEmergency(usersWithFirstEmail);

    //         setLoading(false);
    //     } catch (error) {
    //         setLoading(true);
    //         console.log(error);
    //     }
    // };

    // const fetchUsers = async (input: string) => {
    //     if (input.length > 2) {
    //         try {
    //             const users: any = [];
    //             const { data } = await axios.get(`/erp/users?name=${input}`);
    //             data.forEach((user: any) => {
    //                 users.push({
    //                     ...user,
    //                     value: user.id,
    //                     label: `${user.first_name} ${user.last_name} | ${user.email}`
    //                 })
    //             });
    //             setUsersData(users);
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    // }

    useEffect(() => {
        // initialFetchGroupsAndUsers();
        loadGroupData()
        loadUserData()
    }, []);

    // const updateSettings = async () => {
    //     const usersWithPermission: Array<number> = selectedUsers.map((user) => { return user.value });
    //     const groupsWithPermission: Array<number> = selectedGroups.map((group) => {return group.value });

    //     const usersWithEmail: Array<number> = selectedUsersEmailEmergency.map((user) => { return user.value });
    //     const groupsWithEmail: Array<number> = selectedGroupsEmailEmergency.map((group) => { return group.value });
        
    //     const usersWithFirstEmail: Array<number> = selectedUsersFirstEmailEmergency.map((user) => { return user.value });
    //     const groupsWithFirstEmail: Array<number> = selectedGroupsFirstEmailEmergency.map((group) => { return group.value });

    //     try {
    //         await axios.put('/erp/emergency/settings/permission', {
    //             users: usersWithPermission,
    //             groups: groupsWithPermission
    //         })
    //         await axios.put('/erp/emergency/settings/email', {
    //             users: usersWithEmail,
    //             groups: groupsWithEmail
    //         })
    //         await axios.put('/erp/emergency/settings/first_email', {
    //             users: usersWithFirstEmail,
    //             groups: groupsWithFirstEmail
    //         })
    //         alert('The Emergency Ticket settings were updated!');
    //         initialFetchGroupsAndUsers();
    //     } catch (error) {
    //         alert('Error at updating the settings. Please refresh the page and try again.');
    //     }
    // };

    async function updateSettings2() {
        const usersWithPermission: Array<number> = selectedUsers2.map((user) => { return user.value })
        const groupsWithPermission: Array<number> = selectedGroups2.map((group) => {return group.value })

        const usersWithEmail: Array<number> = selectedUsersEmailEmergency2.map((user) => { return user.value })
        const groupsWithEmail: Array<number> = selectedGroupsEmailEmergency2.map((group) => { return group.value })
        
        const usersWithFirstEmail: Array<number> = selectedUsersFirstEmailEmergency2.map((user) => { return user.value })
        const groupsWithFirstEmail: Array<number> = selectedGroupsFirstEmailEmergency2.map((group) => { return group.value })

        const numberCalls = phoneCalls.map(number => number.value)
        const numberSMS = phoneSMS.map(number => number.value)

        const data = {
            usersWithPermission,
            groupsWithPermission,
            usersWithEmail,
            groupsWithEmail,
            usersWithFirstEmail,
            groupsWithFirstEmail,
            numberCalls,
            numberSMS
        }

        try {
            await axios.put('/erp2/emergency/settings', data)
                .then(() => {alert('The Emergency Ticket settings were updated!')})
        } catch (error) {
            alert("The Emergency Ticket settings weren't update. Try again later!")
        }

        loadGroupData()
        loadUserData()
    }

    const createOption = (label: string) => ({
        label,
        value: label,
    })

    const handleKeyDownCall: KeyboardEventHandler = (event) => {
        if (!inputPhoneCall) return
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            setPhoneCalls((prev) => [...prev, createOption(inputPhoneCall)]);
            setInputPhoneCall('')
            event.preventDefault()
        }
    }

    const handleKeyDownSMS: KeyboardEventHandler = (event) => {
        if (!inputPhoneSMS) return
        switch (event.key) {
          case 'Enter':
          case 'Tab':
            setPhoneSMS((prev) => [...prev, createOption(inputPhoneSMS)]);
            setInputPhoneSMS('')
            event.preventDefault()
        }
    }

    return (
        !loaded ?
            <Loader />
            :
        <>
            <div className="ms-3 me-3 mb-3 pt-3">
                <div className="container-settings mb-3">
                    <h4>Included in emergency tickets</h4>
                    <p>Groups and users assigned to this category will be able to see the emergency ticket notifications</p>
                    <h4>Groups</h4>
                    {/* <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedGroups}
                        onChange={(e: any) => setSelectedGroups(e)}
                        placeholder=""
                    /> */}
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData2}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedGroups2}
                        onChange={(e: any) => setSelectedGroups2(e)}
                        placeholder=""
                    />
                    <br />
                    <h4>Users</h4>
                    {/* <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={usersData}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedUsers}
                        onChange={(e: any) => setSelectedUsers(e)}
                        onInputChange={(e: any) => {
                            fetchUsers(e);
                        }}
                        placeholder=""
                    /> */}
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={usersData2}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedUsers2}
                        onChange={(e: any) => setSelectedUsers2(e)}
                        placeholder=""
                    />
                </div>

                <div className="container-settings mb-3">
                    <h4>Receiving all notifications</h4>
                    <p>Groups and users assigned to this category will be able to receive all the emergency ticket notifications by e-mail</p>
                    <h4>Groups</h4>
                    {/* <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedGroupsEmailEmergency}
                        onChange={(e: any) => setSelectedGroupsEmailEmergency(e)}
                        placeholder=""
                    /> */}
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData2}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedGroupsEmailEmergency2}
                        onChange={(e: any) => setSelectedGroupsEmailEmergency2(e)}
                        placeholder=""
                    />
                    <br />
                    <h4>Users</h4>
                    {/* <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={usersData}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedUsersEmailEmergency}
                        onChange={(e: any) => setSelectedUsersEmailEmergency(e)}
                        onInputChange={(e: any) => {
                            fetchUsers(e);
                        }}
                        placeholder=""
                    /> */}
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={usersData2}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedUsersEmailEmergency2}
                        onChange={(e: any) => setSelectedUsersEmailEmergency2(e)}
                        placeholder=""
                    />
                </div>

                <div className="container-settings mb-3">
                    <h4>Receiving only the first notification</h4>
                    <p>Groups and users assigned to this category will be able to receive only the first emergency ticket notification by e-mail</p>
                    <h4>Groups</h4>
                    {/* <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedGroupsFirstEmailEmergency}
                        onChange={(e: any) => setSelectedGroupsFirstEmailEmergency(e)}
                        placeholder=""
                    /> */}
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData2}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedGroupsFirstEmailEmergency2}
                        onChange={(e: any) => setSelectedGroupsFirstEmailEmergency2(e)}
                        placeholder=""
                    />
                    <br />
                    <h4>Users</h4>
                    {/* <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={usersData}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedUsersFirstEmailEmergency}
                        onChange={(e: any) => setSelectedUsersFirstEmailEmergency(e)}
                        onInputChange={(e: any) => {
                            fetchUsers(e);
                        }}
                        placeholder=""
                    /> */}
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={usersData2}
                        className="basic-select"
                        classNamePrefix="select"
                        value={selectedUsersFirstEmailEmergency2}
                        onChange={(e: any) => setSelectedUsersFirstEmailEmergency2(e)}
                        placeholder=""
                    />
                </div>

                <div className="container-settings mb-3">
                    <h4>Calls and SMS notifications</h4>
                    <p>Phone numbers assigned to this category will receive calls or SMS notifications of new emergency tickets created</p>
                    <p>Example: 1234567890</p>
                    <h4>Call Number(s)</h4>
                    <CreatableSelect 
                        components={{DropdownIndicator: null}}
                        inputValue={inputPhoneCall}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={newValue => setPhoneCalls(newValue)}
                        onInputChange={newValue => setInputPhoneCall(newValue)}
                        onKeyDown={handleKeyDownCall}
                        value={phoneCalls}
                    />
                    <br />
                    <h4>SMS Number(s)</h4>
                    <CreatableSelect 
                        components={{DropdownIndicator: null}}
                        inputValue={inputPhoneSMS}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={newValue => setPhoneSMS(newValue)}
                        onInputChange={newValue => setInputPhoneSMS(newValue)}
                        onKeyDown={handleKeyDownSMS}
                        value={phoneSMS}
                    />
                </div>
                
                {/* <button className="btn border-1" style={{ backgroundColor: '#7F43F5', color: '#fff' }} onClick={() => updateSettings()}>Update Settings</button> */}
                <button className="btn border-1" style={{ backgroundColor: '#7F43F5', color: '#fff' }} onClick={updateSettings2}>Update Settings</button>
            </div>
        </>
    )
}