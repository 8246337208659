import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { FiPaperclip } from 'react-icons/fi';
import { useRouteMatch, useHistory } from 'react-router-dom'
import axios from '../../../services/axios';
import Loader from '../../../components/Loader/index';
import TimeFormatter from '../../../components/utils/TimeFormatter';
interface imageFormat {
    selectedFile: "",
    type: "",
}

interface imageArray extends Array<imageFormat> { }

interface ticketData {
    id: string,
    ticket_id: number,
    profile_id: string,
    location_id: [
        {
            value: string,
            label: string
        }
    ],
    subject: string,
    description: string,
    system: string,
    phone_number: string,
    requester_name: string,
    requester_image: string,
    data_needed: string,
    status: string,
    priority: string,
    assignee: [
        {
            agents: string,
            end_users: string
        }
    ],
    url_image: string,
    created_at: string,
    updated_at: string
}

interface commentData {
    id: string,
    ticket_id: string,
    author_id: string,
    author_name: string,
    description: string,
    permission_type: string,
    url_image: [],
    created_at: string,
    updated_at: string | null,
}

interface Groups {
    value: number;
    label: string;
}

interface Users {
    id: number;
    first_name: string;
    last_name: string;
    full_name: string;
    email: string;
    has_all_locations: number;
    group_name: string;
    location_name: number;
    permission_emergency: boolean;
    value: number;
    label: string;
}

interface TicketGmailProp {
    body: string[]
    header: {
        date: string[]
        from: string[]
        subject: string[]
    }
}

interface commentsArray extends Array<commentData> { }

const author_name = `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`;
const user_permission = localStorage.getItem('sr_card_access');
const user_id = localStorage.getItem('id');

const SingleTicket: React.FC = () => {
    const [description, setDescription] = useState<string>("");
    const [ticketData, setTicketData] = useState<ticketData>();
    const [commentData, setCommentData] = useState<commentsArray>();
    const [additionalFollowersData, setAdditionalFollowersData] = useState<Array<Users>>([]);
    const [imageFile, setImageFile] = useState<imageArray>();
    // const [status, setStatus] = useState<string>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [ticketGmailData, setTicketGmailData] = useState<TicketGmailProp[]>()

    const [usersData, setUsersData] = useState<Array<Users>>([]);

    const [selectedUsersPermission, setSelectedUsersPermission] = useState<Array<Groups>>([]);
    const [selectedGroupsEmailEmergency, setSelectedGroupsEmailEmergency] = useState<Array<Groups>>([]);
    const [selectedUsersEmailEmergency, setSelectedUsersEmailEmergency] = useState<Array<Users>>([]);

    useEffect(() => {
        initialFetchGroupsAndUsers();
        handleLoad();
        // eslint-disable-next-line
    }, [])

    const history = useHistory();
    const router = useRouteMatch();
    const id: any = router.url.split('/')[2] || '';
    const permission_type = localStorage.getItem('sr_card_access');
    async function handleLoad() {
        try {
            await axios.get(`/emergency/${id}`).then(async (response) => {
                setTicketData(response.data.results[0])
                setCommentData(response.data.comments)
                const additionalFollowers: any = []
                response.data.additional_users.forEach((follower: any) => {
                    additionalFollowers.push({
                        ...follower,
                        value: follower.id,
                        label: `${follower.first_name} ${follower.last_name} | ${follower.email}`
                    })
                })
                setAdditionalFollowersData(additionalFollowers)

                const data = {
                    ticketId: response.data.results[0].ticket_id,
                    ticketSubject: response.data.results[0].subject,
                    ticketLocation: response.data.results[0].location_id,
                    id: response.data.results[0].id
                }

                await axios.post('/emergency/ticket/emails', data).then((response) => {
                    setTicketGmailData(response.data)
                })

                setLoaded(true)
            })
        } catch (error) {
            history.push(`/emergency`)
        }
    }

    const initialFetchGroupsAndUsers = async () => {
        try {
            const groups: any = [];
            const { data } = await axios.get('/erp/groups');
            await data.forEach((group: any) => {
                groups.push({
                    ...group,
                    value: group.id,
                    label: group.group_name
                })
            });

            const groupsWithEmail: any = [];
            await data.forEach((group: any) => {
                if (group.emails_emergency === true) {
                    groupsWithEmail.push({
                        ...group,
                        value: group.id,
                        label: group.group_name
                    })
                }
            });
            setSelectedGroupsEmailEmergency(groupsWithEmail);

            const userEmailResults = await axios.get('/erp/users?emails_emergency=true&limit=50');
            const usersWithEmail: any = [];
            await userEmailResults.data.forEach((user: any) => {
                usersWithEmail.push({
                    ...user,
                    value: user.id,
                    label: `${user.first_name} ${user.last_name} | ${user.email}`
                })
            })
            setSelectedUsersEmailEmergency(usersWithEmail);
        } catch (error) {
            console.log(error);
        }

        //======================================================

        const config = await axios.get('/erp2/emergency/settings')
            .then(data => data.data[0])
            .catch(error => console.log('>> ', error))

        const erpUsers = await axios.get('/erp2/users').then(data => data.data).catch(e => console.log(e))
        setUsersData(erpUsers.map((user: any) => {return {...user, value: user.id, label: `${user.first_name} ${user.last_name} | ${user.email}`}}))

        const usersPermissionFiltered = erpUsers
            .filter((user: any) => config.users_with_permission.includes(user.id))
            .map((user: any) => {return {value: user.id, label: `${user.first_name} ${user.last_name} | ${user.email}`}})

        const groupsFiltered = await axios.get('/erp2/groups')
            .then(data => {
                return data.data
                    .filter((group: any) => config.groups_with_email.includes(group.group_id))
                    .map((group: any) => {return {value: group.group_id, label: group.name}})
            })
            .catch(e => console.log(e))

        const usersFiltered = erpUsers
            .filter((user: any) => config.users_with_email.includes(user.id))
            .map((user: any) => {return {value: user.id, label: `${user.first_name} ${user.last_name} | ${user.email}`}})
        
        setSelectedUsersPermission(usersPermissionFiltered)
        setSelectedGroupsEmailEmergency(groupsFiltered)
        setSelectedUsersEmailEmergency(usersFiltered)
    };

    const fetchUsers = async (input: string) => {
        if (input.length > 2) {
            try {
                const users: any = [];
                const { data } = await axios.get(`/erp/users?name=${input}`);
                data.forEach((user: any) => {
                    users.push({
                        ...user,
                        value: user.id,
                        label: `${user.first_name} ${user.last_name} | ${user.email}`
                    })
                });
                setUsersData(users);
            } catch (error) {
                console.log(error);
            }
        }
    }

    function fileSelect(props: any) {
        function multiImages(props: any, size: any) {
            const reader = new FileReader()
            reader.readAsDataURL(props[size])
            reader.onload = (e: any) => {
                setImageFile((prevState) => [
                    ...prevState!,
                    {
                        selectedFile: e?.target?.result,
                        type: props[size].type
                    }
                ])
            }
        }

        const reader = new FileReader();
        reader.readAsDataURL(props[0])
        reader.onload = (e: any) => {
            setImageFile([
                {
                    selectedFile: e?.target?.result,
                    type: props[0].type
                },
            ])
        }
        setTimeout(() => {
            for (let i = 1; i < props.length; i++) {
                multiImages(props, i);
            }
        }, 500)
    }


    async function createComment() {
        const created_at = new Date().toString().split("").splice(4, 17).join("");
        setLoaded(true);

        const dataSend = ({
            author_id: user_id,
            author_name,
            description,
            permission_type,
            images: imageFile,
            created_at,
            selectedUsers: additionalFollowersData
        })

        try {
            axios.post(`emergency/additional_users`, {
                ticket_id: id,
                users_erp: additionalFollowersData
            })
            axios.post(`emergency/${id}`, dataSend)
            setDescription('')
            handleLoad()
        } catch (error) {
            alert(error)
            setLoaded(true)
        }
    }

    async function handleUpdateStatus(status: any) {
        const updated_at = new Date().toString().split("").splice(4, 17).join("");
        const dataSend = {
            status: status,
            updated_at
        }

        try {
            setLoaded(false)
            await axios.put(`/emergency/status/${id}`, dataSend)
            handleLoad()
        } catch (error) {
            setLoaded(false)
            alert(error)
            handleLoad()
        }
    }

    return (
        loaded ?
            <div className="body-content">
                <h4 id="page-title">Ticket #{ticketData?.ticket_id}</h4>
                <h5>Ticket module: <b id="page-title">Emergency</b></h5>

                <div className="single-ticket">
                    <div className="ticket-info">
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <h5>Subject: <p id="page-title">{ticketData?.subject}</p></h5>
                            <h5>Requester: <p>{ticketData?.requester_name}</p></h5>
                            <h5>Location: <p>{ticketData?.location_id[0].label}</p></h5>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                            <h5>Created: <p>{TimeFormatter(ticketData?.created_at)}</p></h5>
                            <h5>Last Update: <p>{commentData?.length ? TimeFormatter(commentData[0].created_at) : TimeFormatter(ticketData?.created_at)}</p></h5>
                        </div>
                    </div>

                    <div style={{ display: user_permission === 'END_USER' ? 'none' : 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
                        {author_name === 'Silvia Lintner' ?
                            (
                                <>
                                    <h5>Status</h5>
                                    <Select
                                        id="demo-simple-select"
                                        name="colors"
                                        options={[{ value: 'open', label: 'Open' }, { value: 'awaiting_your_reply', label: 'Awaiting Your Reply' }, { value: 'solved', label: 'Solved' }, { value: 'on_hold', label: 'On Hold' }]}
                                        className="basic-select"
                                        classNamePrefix="select"
                                        onChange={e => handleUpdateStatus(e!.value)}
                                        value={[{ value: ticketData?.status, label: ticketData?.status }]}
                                    />
                                    <br />
                                </>
                            ) : (
                                <>
                                    <h5>Status</h5>
                                    <Select
                                        id="demo-simple-select"
                                        name="colors"
                                        options={[{ value: 'open', label: 'Open' }, { value: 'awaiting_your_reply', label: 'Awaiting Your Reply' }, { value: 'solved', label: 'Solved' }, { value: 'on_hold', label: 'On Hold' }]}
                                        className="basic-select"
                                        isDisabled={true}
                                        classNamePrefix="select"
                                        onChange={e => handleUpdateStatus(e!.value)}
                                        value={[{ value: ticketData?.status, label: ticketData?.status }]}
                                    />
                                    <br />
                                </>
                            )
                        }
                        <h5>Followers (Agents):</h5>
                        <Select
                            isMulti
                            id="demo-simple-select"
                            name="colors"
                            options={selectedUsersPermission}
                            value={selectedUsersPermission}
                            isDisabled={true}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={e => console.log(e)}
                        />
                        <br />
                        <h5>Followers (Groups):</h5>
                        <Select
                            isMulti
                            id="demo-simple-select"
                            name="colors"
                            options={selectedGroupsEmailEmergency}
                            value={selectedGroupsEmailEmergency}
                            isDisabled={true}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={e => console.log(e)}
                        />
                        <br />
                        <h5>Followers (Users):</h5>
                        <Select
                            isMulti
                            id="demo-simple-select"
                            name="colors"
                            options={selectedUsersEmailEmergency}
                            value={selectedUsersEmailEmergency}
                            isDisabled={true}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={e => console.log(e)}
                        />
                        <br />
                        <h5>Add followers (for this ticket):</h5>
                        <Select
                            isMulti
                            id="demo-simple-select"
                            name="colors"
                            options={usersData}
                            value={additionalFollowersData}
                            className="basic-select"
                            classNamePrefix="select"
                            onChange={(e: any) => setAdditionalFollowersData(e)}
                            // onInputChange={(e: any) => {
                            //     fetchUsers(e);
                            // }}
                        />
                    </div>
                </div>

                <div className="ticket-comments">
                    <h4 className="mt-4" id="page-title">Comments</h4>
                    <div className="personal-comment">
                        <textarea
                            id="w3review"
                            name="w3review"
                            rows={4}
                            cols={50}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                        <div className="col-4 mt-4" style={{width: '100%'}}>
                            <label className="btn" style={{ display: (imageFile === undefined) ? "" : "none" }} htmlFor='inputFileImg'><FiPaperclip width={30} height={30} /></label>
                            <input style={{ display: (imageFile === undefined) ? "none" : "" }} id="inputFileImg" type="file" onChange={(e) => fileSelect(e.target.files)} className="form-control mb-3" accept=".jpg,.png" multiple />
                            <button onClick={() => createComment()} className="btn btn-custom">Update Ticket</button>
                        </div>
                    </div>

                    {ticketGmailData ? ( ticketGmailData.map(ticket => (
                        <div className={"personal-comment"} key={ticket.header.date[0]}>
                            <p className="float-end"><b>Gmail</b></p>
                            <div>
                                <h5>{ticket.header.from}</h5>

                                <p>{TimeFormatter(ticket.header.date[0], true)}</p>
                            </div>

                            {ticket.body.map(body => (
                                <p key={body}>{body}</p>
                            ))}
                        </div>
                    )) ) : null}

                    {commentData?.map((e: any) => (
                        <div className={(user_id === e.author_id ? "personal-comment" : (e.permission_type === "END_USER" ? "user-comment" : "agent-comment"))} key={e.comment_id}>
                            <p className="float-end"><b>#{e.comment_id}</b></p>
                            <div>
                                <h5>{e.author_name}</h5>
                                <p>{TimeFormatter(e.created_at)}</p>
                            </div>
                            <p>{e.description}</p>
                        </div>
                    ))}
                    {/* 
                    <div className="personal-comment"> // id
                    <div className="user-comment">
                    <div className="agent-comment">
                    */}
                    <div className="personal-comment">
                        <p className="float-end"><b>INITIAL REQUEST</b></p>
                        <div>
                            <p>{ticketData?.description}</p>
                        </div>
                    </div>
                </div>
            </div >
            :
            <Loader />
    )
}
export default SingleTicket;