import React, { useState } from 'react';
import Select from 'react-select';
import { locationsData, systemTypes } from '../../data';
import TextField from '@material-ui/core/TextField';

var searchParams = '/emergency/search/?order=desc'
export function AdvancedSearch(props: any) {
    const [advancedSearch, setAdvancedSearch] = useState<boolean>(false)
    const [subject, setSubject] = useState<string>()
    const [requesterName, setRequesterName] = useState<string>();
    const [requestId, setRequestId] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [status, setStatus] = useState<string>()
    const [location, setLocation] = useState<string>();
    const [system, setSystem] = useState<string>();

    function createUrl() {
        searchParams = '/search/?order=desc'
        if (subject) {
            searchParams = `${props.url}${searchParams}&subject=${subject}`;
        } else {
            searchParams = `${props.url}/search/?order=desc`;
        }
        if (status) {
            searchParams = `${searchParams}&status=${status}`;
        }
        if (location) {
            searchParams = `${searchParams}&location=${location}`;
        }
        if (system) {
            searchParams = `${searchParams}&system=${system}`;
        }
        if (requesterName) {
            searchParams = `${searchParams}&requester_name=${requesterName}`;
        }
        if (requestId) {
            searchParams = `${searchParams}&request_id=${requestId}`;
        }
        if (description) {
            searchParams = `${searchParams}&description=${description}`;
        }
    }

    return (
        <div className="search-options">
            <h4>Search All Fields</h4>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '1rem 0' }}>
                <input
                    type="number"
                    name="allFields"
                    maxLength={250}
                    onBlur={createUrl}
                    onChange={e => setRequestId(e.target.value)}
                    placeholder='Request ID:'
                />
                <input
                    type="text"
                    name="allFields"
                    maxLength={250}
                    style={{marginLeft: '8px'}}
                    onBlur={createUrl}
                    onChange={e => setRequesterName(e.target.value)}
                    placeholder='Requester:'
                />
                <input
                    type="text"
                    name="allFields"
                    maxLength={250}
                    style={{marginLeft: '8px'}}
                    onBlur={createUrl}
                    onChange={e => setSubject(e.target.value)}
                    placeholder='Subject:'
                />
                <input
                    type="text"
                    name="allFields"
                    maxLength={250}
                    style={{marginLeft: '8px'}}
                    onBlur={createUrl}
                    onChange={e => setDescription(e.target.value)}
                    placeholder='Description:'
                />
            </div>
            <div className='top-search' style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'flex-start', margin: '1rem 0' }}>
                <Select
                        id="demo-simple-select"
                        name="colors"
                        options={locationsData}
                        className="basic-select"
                        classNamePrefix="select"
                        onChange={(option: any) => setLocation(option.label)}
                        // onBlur={createUrl}
                        // onChange={handleChange}
                        placeholder="Location:"
                />
                <Select
                        id="demo-simple-select"
                        name="colors"
                        options={systemTypes}
                        className="basic-select"
                        classNamePrefix="select"
                        onChange={(option: any) => setSystem(option.label)}
                        onBlur={createUrl}
                        // onChange={handleChange}
                        placeholder="System:"
                />
                <Select
                    id="demo-simple-select"
                    name="colors"
                    options={[{ value: 'open', label: 'Open' }, { value: 'awaiting_your_reply', label: 'Awaiting Your Reply' }, { value: 'solved', label: 'Solved' }, { value: 'on_hold', label: 'On Hold' }, { value: 'DELETED', label: 'Deleted' }]}
                    className="basic-select"
                    classNamePrefix="select"
                    onChange={e => setStatus(e!.value)}
                    onBlur={createUrl}
                    placeholder="Status:"
                />
            </div>
        
            <div className='advanced-search' style={{ display: advancedSearch ? '' : 'none', height: advancedSearch ? '100%' : '0' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '1rem 0' }}>
                    <TextField
                        // id="standard-basic"
                        label="Requested ID"
                        onChange={e => searchParams = e.target.value}
                    />
                    <TextField
                        // id="standard-basic"
                        label="Requested On"
                        onChange={e => searchParams = e.target.value}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '1rem 0' }}>
                    <TextField
                        // id="standard-basic"
                        label="Title"
                        onChange={e => searchParams = e.target.value}
                    />
                    {/* <TextField
                    // id="standard-basic"
                    label="Location"
                    onChange={e => searchParams = e.target.value}
                /> */}
                    <Select
                        id="demo-simple-select"
                        name="colors"
                        options={locationsData}
                        className="basic-select"
                        classNamePrefix="select"
                        // onChange={handleChange}
                        placeholder="Location"
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '1rem 0' }}>
                    <TextField
                        // id="standard-basic"
                        label="Description"
                        onChange={e => searchParams = e.target.value}
                    />
                    {/* <TextField
                    // id="standard-basic"
                    label="Status"
                    onChange={e => searchParams = e.target.value}
                /> */}
                    <Select
                        id="demo-simple-select"
                        name="colors"
                        options={locationsData}
                        className="basic-select"
                        classNamePrefix="select"
                        // onChange={handleChange}
                        placeholder="Status"
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '1rem 0' }}>
                    <TextField
                        // id="standard-basic"
                        label="Issue Type"
                        onChange={e => searchParams = e.target.value}
                    />
                    <TextField
                        // id="standard-basic"
                        label="Headed By"
                        onChange={e => searchParams = e.target.value}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '1rem 0' }}>
                    <TextField
                        // id="standard-basic"
                        label="Closed On"
                        onChange={e => searchParams = e.target.value}
                    />
                    <TextField
                        // id="standard-basic"
                        label="Updated On"
                        onChange={e => searchParams = e.target.value}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', margin: '1rem 0' }}>
                    <TextField
                        // id="standard-basic"
                        label="Ticket Type"
                        onChange={e => searchParams = e.target.value}
                    />
                    <TextField
                        // id="standard-basic"
                        label="No Response Days"
                        onChange={e => searchParams = e.target.value}
                    />
                </div>
            </div>
        </div>
    )
}

export function Search() {
    return (
        {
            result: searchParams,
        }
    )
}

const exportObject = {
    AdvancedSearch,
    Search,
}

export default exportObject;