import React, { useState, useEffect } from 'react';
import ProfileInfo from './ProfileInfo';

const LoginButton = (props: any) => {
    const [extended, setExtended] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [image, setImage] = useState<string>('');
    useEffect(() => {
        onLoaded()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onLoaded() {
        if (window.localStorage.getItem('firstName') === null || window.localStorage.getItem('firstName') === undefined
            || window.localStorage.getItem('lastName') === null || window.localStorage.getItem('lastName') === undefined
            || window.localStorage.getItem('image_url') === null || window.localStorage.getItem('image_url') === undefined
        ) {
            setTimeout(() => {
                setFirstName(localStorage.getItem('firstName')!)
                setLastName(localStorage.getItem('lastName')!)
                setImage(localStorage.getItem('image_url')!)
            }, 500);
        } else {
            setFirstName(localStorage.getItem('firstName')!)
            setLastName(localStorage.getItem('lastName')!)
            setImage(localStorage.getItem('image_url')!)
        }
    }

    function openNav() {
        setExtended(true);
    }

    function closeNav() {
        setExtended(false);
    }

    return (
        <>
            <div id="LogNav" className="sidenav" style={{ width: extended ? props.width : '0' }}>
                <span className="closebtn" onClick={closeNav}>&times;</span>
                <h1>User Profile</h1>
                <ProfileInfo />
            </div>

            <button
                style={{ display: loaded ? "" : "none" }}
                onClick={openNav} type="button" id="user-button">
                <img onLoad={() => setLoaded(true)} id="profile-image" src={image} alt="UserImage" />
                <h5>{firstName} {lastName}</h5>
            </button>
        </>

    )


}

export default LoginButton;
