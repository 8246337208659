const EmergencyTicketSuccess: React.FC = () => {
    return (
        <div className="body-content">
          <div className="ticket-success">
            <h3>Ticket successfully created!</h3>

            <p>Please check the email provided in the form to know the progress of your ticket.</p>
          </div>
        </div>
    )
}
export default EmergencyTicketSuccess;