import React, { useEffect, useState } from 'react';
import Card from '../../components/Settings/Card';
import Game from '../../components/Settings/Games';
import Emergency from '../../components/Settings/Emergency';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';

const Announcements: React.FC = () => {
    const history = useHistory()
    const [logged, setLogged] = useState(false)
    const [btn, setBtn] = useState(
        {
            content: 3,
            btnCard: '#fff',
            btnGame: '#7F43F5',
        },
    );

    useEffect(() => {
        try {
          if(localStorage.getItem('token')) {
              jwt.verify(
                  localStorage.getItem('token')!.toString(),
                  '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
                  (err, decoded) => {
                      if (decoded) {
                          setLogged(true)
                      } else {
                          setLogged(false)
                          alert('Token invalid!')
                          history.push('/')
                      }
                  }    
              )
          } else {
              setLogged(false)
              alert('Not logged on FEG System!')
              history.push('/')
          }
        } catch (error) {
          console.log(error)
          setLogged(false)
          alert('Error on load the permissions!')
          history.push('/')
        }
      }, [])

    function changeContent() {
        if (btn.content === 1) {
            return <Card />
        }
        if (btn.content === 2) {
            return <Game />
        }
        if (btn.content === 3) {
            return <Emergency />
        }
    }

    return (
        <div className="body-content">
            <div className="settings-header">
                {/* <button className="btn border-1" style={{ backgroundColor: btn.btnCard, color: btn.btnGame }} onClick={() => setBtn({ content: 1, btnCard: '#fff', btnGame: '#7F43F5' })}>Debit Card Related Settings</button> */}
                {/* <button className="btn border-1" style={{ backgroundColor: btn.btnGame, color: btn.btnCard }} onClick={() => setBtn({ content: 2, btnCard: '#7F43F5', btnGame: '#fff' })}>Game Related Settings</button> */}
                <button className="btn border-1" style={{ backgroundColor: btn.btnGame, color: btn.btnCard }} onClick={() => setBtn({ content: 3, btnCard: '#7F43F5', btnGame: '#fff' })}>Emergency Ticket Settings</button>
            </div>
            <br />
            <div className="settings-content">
                {
                    changeContent()
                }
            </div>
        </div >
    )
}

export default Announcements;
