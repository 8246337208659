/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'
import * as ROUTES from './routerList'

import Home from '../../pages/Home';
import Credit from '../../pages/CreditCard';
import NewCredit from '../../pages/CreditCard/New';
import SingleCard from '../../pages/CreditCard/Single';
import Games from '../../pages/GamesRelated';
import NewGames from '../../pages/GamesRelated/New';
import EmergencyTicket from '../../pages/EmergencyTicket';
import NewEmergency from '../../pages/EmergencyTicket/New';
import NewEmergencySuccess from '../../pages/EmergencyTicket/New/Success';
import SingleEmergency from '../../pages/EmergencyTicket/Single';
import Settings from '../../pages/Settings';
import Header from '../../components/Header/index';
import SideBar from '../../components/SideBar';

const PrivateRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <SideBar />
      <Header />
      <Switch>
        <Route exact path={ROUTES.NEWHOME} component={Home} />
        {/*<Route exact path={ROUTES.HOME} component={Home} /> */}
        {/*<Route exact path={ROUTES.CARD} component={Credit} /> */}
        {/*<Route exact path={ROUTES.NEWCARD} component={NewCredit} /> */}
        {/*<Route exact path={ROUTES.SINGLECARD} component={SingleCard} /> */}
        {/*<Route exact path={ROUTES.GAMES} component={Games} /> */}
        {/*<Route exact path={ROUTES.NEWGAMES} component={NewGames} /> */}
        <Route exact path={ROUTES.EMERGENCY} component={EmergencyTicket} />
        <Route exact path={ROUTES.NEWEMERGENCY} component={NewEmergency} />
        <Route exact path={ROUTES.NEWEMERGENCYSUCCESS} component={NewEmergencySuccess} />
        <Route exact path={ROUTES.SINGLEEMERGENCY} component={SingleEmergency} />
        <Route exact path={ROUTES.SETTINGS} component={Settings} />
      </Switch>
    </BrowserRouter>
  )
}

export default withRouter(PrivateRoutes)
