import React, { useState, useEffect } from 'react';
import Filters from '../../components/Shortcuts/Filters';
import Shortcut from '../../components/Shortcuts';
import Tables from '../../components/Tables/tables';
import axios from '../../services/axios';
import Loader from '../../components/Loader';

// old const
const user_id = localStorage.getItem('id');
const user_permission = localStorage.getItem('sr_card_access');

interface totalTickets {
  total: number,
  open: number,
  solved: number,
}

export default function Home() {
  const [logged, setLogged] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [userId, setUserId] = useState('')
  const [userPermission, setUserPermission] = useState('')
  const [total, setTotal] = useState<totalTickets>();
  useEffect(() => {
    // reload()
    handleLoad()
  }, [])


  function reload() {
    // if (!user_permission || user_permission === null) {
    //   window.location.reload()
    //   // setTimeout(() => {
    //   // }, 500)
    // }
    window.location.reload()
  }

  async function handleLoad() {
    try {
      setLoaded(false)
      const response = await axios.get('/emergency/total')
      setTotal(response.data)

      if (localStorage.getItem('token')) {
        setUserId(localStorage.getItem('id')!)
        setUserPermission(localStorage.getItem('sr_card_access')!)
        setLogged(true)
      } else {
        setLogged(false)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoaded(true)
    }
  }

  const Loading = (
    <>
      <div className="body-content">
        <div className='UserLogIn'>
          <Loader />
        </div>
      </div>
    </>
  )

  const UserNotLogged = (
    <>
      <div className="body-content">
        <div className='UserLogIn'>
          <h4>You need to be logged on the FEG Home to so see historic information about your tickets.</h4>
          <h4>Click the button below to login and reload this page.</h4>
          <br />
          <a href={`${process.env.REACT_APP_ACCOUNT_URL}/logout`} target="_blank">Login on the FEG Home</a>
          <br /> <br />
          <p>Already logged in? Click <span className='reload' onClick={reload}>here</span> to reload the page.</p>
        </div>
      </div>
    </>
  )

  const homeEndUser = (
    <>
      <div className="body-content">
        <div>
          <h3 id="table-title" style={{ marginTop: '1rem' }}>Shortcuts</h3>
          <div className="shortcut-filter overflow" style={{ flexDirection: 'row' }}>
            <Shortcut ImageName="/shortcuts/EmergencyTicket.png" Content="Emergency Ticket" srcURL="/emergency" />
          </div>
        </div>
        <div className="overflow">
          <Tables url={`/emergency/mytickets/?limit=5&order=desc&id=${userId}`} redirect='emergency' title="My tickets" />
        </div>
      </div>
    </>
  )

  const homeAgent = (
    <>
      <div className="body-content">
        <div>
          <h3>Summary of the month</h3>
          <div className="shortcut-filter overflow" style={{ flexDirection: 'row' }}>
            <Filters ImageName="/filter/TotalTicketsIcon.png" Total={total?.total} Content="Total No of Tickets" />
            <Filters ImageName="/filter/ClosedTickets.png" Total={total?.solved} Content="Solved Tickets" />
            <Filters ImageName="/filter/PendingTickets.png" Total={total?.open} Content="Open Tickets" />
            {/* <Filters ImageName="/filter/TotalTicketsIcon.png" Total="1234" Content="Total No of Tickets" />
          <Filters ImageName="/filter/NewTicketsIcon.png" Total="1234" Content="New Tickets" />
          <Filters ImageName="/filter/ClosedTickets.png" Total="1234" Content="Solved Tickets" />
          <Filters ImageName="/filter/PendingTickets.png" Total="1234" Content="Pending Tickets" />
          <Filters ImageName="/filter/UrgentTickets.png" Total="1234" Content="Urgent Tickets" /> */}
          </div>
        </div>
        <div>
          <h3 id="table-title" style={{ marginTop: '1rem' }}>Shortcuts</h3>
          <div className="shortcut-filter overflow" style={{ flexDirection: 'row' }}>
            <Shortcut ImageName="/shortcuts/EmergencyTicket.png" Content="Emergency Ticket" srcURL="/emergency" />
          </div>
        </div>
        <div className="overflow">
          <Tables url={`/emergency/mytickets/?limit=5&order=asc&id=${userId}`} redirect='emergency' title="My tickets" empty="You don't have tickets created" />
        </div>
        <div className="overflow">
          <Tables url={`/emergency/?limit=5&order=desc`} redirect='emergency' title="Last Tickets" empty="No tickets to show"  />
        </div>
      </div>
    </>
  )

  if (loaded) {
    if (logged) {
      return (
        userPermission === 'END_USER' ?
          homeEndUser
          :
          homeAgent
      )
    } else {
      return (
        UserNotLogged
      )
    }
  } else {
    return Loading
  }
}