import NewTicketForm from './Form';
import { Link } from 'react-router-dom';

const EmergencyTicket: React.FC = () => {
    return (
        <div className="body-content2">
            <h4 id="title">FEG Emergency Ticket</h4>
            <p id="page-description"><b>WHAT CONSTITUTES AN EMERGENCY:</b>
                <br />
                Only system issues which severely impair the core functionality of the system, preventing it from selling/recharging
                <br />
                and/or playing cards are considered emergencies
                <br />
                <br />
                If your ticket is not an Emergency, <a href="https://system.fegllc.com/servicerequests" target='_blank'><b>click here</b></a> to create a regular Card Related Ticket.<br/>
                <Link to="/emergency"><b>Click here</b></Link> to see the emergency tickets list.</p>
            <NewTicketForm />
        </div>
    )
}
export default EmergencyTicket;