import React from 'react';
import Select from 'react-select';
import { locationsData, groupsData } from '../../../data';

export default function CardSettings() {
    return (
        <>
            <div className="ms-3 me-3 mb-3 pt-3">
                <div className="container-settings mb-3">
                    <h4>Automatically included in all Tickets</h4>
                    <p>Groups assigned to this category will be able to see All locations, include locations not assigned to that user</p>
                    <h4>Groups</h4>
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData}
                        className="basic-select"
                        classNamePrefix="select"
                        // onChange={handleChange}
                        placeholder=""
                    />
                    <h4>Users</h4>
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={locationsData}
                        className="basic-select"
                        classNamePrefix="select"
                        // onChange={handleChange}
                        placeholder=""
                    />
                </div>
                <div className="container-settings mb-3">
                    <h4>Groups Receiving All Emails</h4>
                    <p>Groups assigned to this category will receive All email notifications for tickets that have been created in a location to which they have been assigned</p>
                    <h4>Groups</h4>
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData}
                        className="basic-select"
                        classNamePrefix="select"
                        // onChange={handleChange}
                        placeholder=""
                    />
                    <h4>Users</h4>
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={locationsData}
                        className="basic-select"
                        classNamePrefix="select"
                        // onChange={handleChange}
                        placeholder=""
                    />
                </div>
                <div className="container-settings">
                    <h4>Groups Receiving 1st Email (Initial Request)</h4>
                    <p>Groups assigned to this category will only receive the 1st email notifications when a ticket  created in a location to which they have been assigned</p>
                    <h4>Groups</h4>
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={groupsData}
                        className="basic-select"
                        classNamePrefix="select"
                        // onChange={handleChange}
                        placeholder=""
                    />
                    <h4>Users</h4>
                    <Select
                        isMulti
                        id="multi-select"
                        name="colors"
                        options={locationsData}
                        className="basic-select"
                        classNamePrefix="select"
                        // onChange={handleChange}
                        placeholder=""
                    />
                </div>
            </div>
        </>
    )
}