import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../Loader';

const Content = (props: any) => {
  const [loaded, setLoaded] = useState(true);

  return (
    <div className="box-content images" style={{display: props.hide ? "none" : ""}}>
      <Link rel="noopener noreferrer" to={`${props.srcURL}` || '#'}>

        {
          (loaded ? <Loader /> : <></>)
        }
        <img src={`${props.ImageName}`} onLoad={() => setLoaded(false)} alt="Shortcut" width={100} height={100} />
        <h3>{props.Content}</h3>
      </Link>
    </div>
  );
}

export default Content;