import React, { useEffect, useState } from 'react';
import Tables from '../../components/Tables/tables';
import Shortcut from '../../components/Shortcuts';
import { AdvancedSearch, Search } from '../../components/AdvancedSearch';
import Loader from '../../components/Loader';
import jwt from 'jsonwebtoken';
import { useHistory } from 'react-router-dom';

const user_id = localStorage.getItem('id');
const user_permission = localStorage.getItem('sr_card_access');

const CreditCard: React.FC = () => {
    const history = useHistory()
    const [logged, setLogged] = useState(false)
    const [onLoad, setOnLoad] = useState<boolean>(false)
    const [url, setUrl] = useState<string>(
        (user_permission === 'END_USER') ?
            `/emergency/mytickets/?limit=5&order=desc&id=${user_id}`
            :
            `/emergency/?limit=5&order=desc`)


    useEffect(() => {
      try {
        if(localStorage.getItem('token')) {
            jwt.verify(
                localStorage.getItem('token')!.toString(),
                '8Zz5tw0Ionm3XPZZfN0NOml3z9FMfmpgXwovR9fp6ryDIoGRM8EPHAB6iHsc0fb',
                (err, decoded) => {
                    if (decoded) {
                        setLogged(true)
                    } else {
                        setLogged(false)
                        alert('Token invalid!')
                        history.push('/')
                    }
                }    
            )
        } else {
            setLogged(false)
            alert('Not logged on FEG System!')
            history.push('/')
        }
      } catch (error) {
        console.log(error)
        setLogged(false)
        alert('Error on load the permissions!')
        history.push('/')
      }
    }, [])
    
    function handleAdvanced() {
        setUrl(Search().result)
        setOnLoad(true)
        setTimeout(() => {
            setOnLoad(false)
        }, 100)
    }

    // AGENT_SUPER_ADMIN
    // STANDARD_AGENT
    // END_USER

    return (
        onLoad ?
            <Loader />
            :
            <div className="body-content">
                <div className="shortcut-filter overflow" style={{ flexDirection: 'row' }}>
                    {logged ? <Shortcut ImageName="/filter/NewTicketsIcon.png" Content="New Emergency Ticket" srcURL="/emergency/new" /> : ''}
                </div>
                <h4 id="page-title">FEG Emergency Ticket</h4>
                <AdvancedSearch url="/emergency" />
                <button type="button" className="btn btn-custom mb-2" onClick={handleAdvanced}>Search</button>
                <div className="overflow">
                    <Tables
                        url={url}
                        redirect='emergency'
                        empty='You have no tickets'
                    />
                </div>
            </div>
    )
}
export default CreditCard;