export const HOME = '/home'
export const DEFAULT = '/*'
export const NEWHOME = '/'
export const CARD = '/cardtickets'
export const NEWCARD = '/cardtickets/new'
export const SINGLECARD = '/card/:id'
export const GAMES = '/gametickets'
export const NEWGAMES = '/gametickets/new'
export const EMERGENCY = '/emergency'
export const NEWEMERGENCY = '/emergency/new'
export const NEWEMERGENCYSUCCESS = '/emergency/new/success'
export const SINGLEEMERGENCY = '/emergency/:id'
export const SETTINGS = '/settings'