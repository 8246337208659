import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiMail, FiFile } from 'react-icons/fi';
import { useCookies } from 'react-cookie'

const ProfileInfo = () => {
    const [, , removeCookie] = useCookies(["token"]);
    const firstName = localStorage.getItem('firstName')
    const lastName = localStorage.getItem('lastName')
    const email = localStorage.getItem('email')
    const image = localStorage.getItem('image_url')
    const history = useHistory();

    function handleLogout() {
        localStorage.clear()
        removeCookie("token")
        window.localStorage.clear();
        setTimeout(() => {
            if (process.env.REACT_APP_ACCOUNT_URL) {
                window.location.href = `${process.env.REACT_APP_ACCOUNT_URL}/logout`
            } else {
                window.location.href = "https://account.systemfeg.com/logout"
            }
        }, 1000)
    }

    return (
        <div className="profile-box">
            <img
                src={image ? image : "/assets/Monograma.png"}
                alt="UserImage"
            />
            <div className="about">
                <h4>{firstName} {lastName}</h4>
                <div className="container">
                    <FiMail color="#478fc8" size={20} />
                    <p>{email}</p>
                </div>
                <button onClick={handleLogout}>Sign Out</button>
            </div>

            <hr />

            <div className="my-profile">
                <FiFile style={{
                    background: '#c8c8d3',
                    borderRadius: '8px',
                    padding: '0.7rem',
                    color: '#1eb6b0'
                }} size={45} />
                <div className="container">
                    <h3>{firstName} {lastName}</h3>
                    <div className="update-profile">
                        <h4>Update your settings</h4>
                        <button className="button" onClick={() => history.push("http://systemfeg.com")}>edit</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ProfileInfo;