/* eslint-disable jsx-a11y/scope */
import React, { useState, useEffect } from 'react';
import Loader from '../Loader';
import axios from '../../services/axios';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
interface Data {
    id: string,
    ticket_id: number,
    profile_id: string,
    location_id: [
        {
            value: string,
            label: string
        }
    ],
    subject: string,
    description: string,
    system: string,
    phone_number: string,
    requester_name: string,
    requester_image: string,
    data_needed: string,
    status: string,
    priority: string,
    assignee: string,
    url_image: string,
    createdAt: string,
    updatedAt: string
}

interface dataArray extends Array<Data> { }

const user_permission = localStorage.getItem('sr_card_access');

export default function Table(props: any) {

    const [loading, setLoading] = useState<boolean>(true)
    const [data, setData] = useState<dataArray>()

    useEffect(() => {
        handleLoading(props.url);
        // eslint-disable-next-line
    }, [])

    async function handleLoading(url: string) {
        try {
            await axios.get(url)
                .then(e => setData(e.data));
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    async function handleDeleteTicket(id: string) {
        const updated_at = new Date().toString().split("").splice(4, 17).join("");
        const dataSend = {
            status: "DELETED",
            updated_at
        }

        try {
            setLoading(true)
            await axios.put(`/${props.redirect}/status/${id}`, dataSend)
            setData(data?.filter(e => e.id !== id));
            setLoading(false)
        } catch (error) {
            setLoading(true)
            console.log(error)
            handleLoading(props.url)
        }
    }
    return (
        loading ?
            <Loader />
            :

            (data!.length < 1) ?
                <h4 id="page-title" className="text-center">{props.empty}</h4>
                :
                <>
                    <div style={{ marginTop: '1rem', display: (props.title) ? '' : 'none' }}>
                        <h3 id="page-title">{props.title}</h3>
                    </div>

                    <table className="table table-striped table-hover table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Requester Name</th>
                                <th scope="col">Subjects</th>
                                <th scope="col">Status</th>
                                <th scope="col">Priority</th>
                                <th scope="col">Assignee</th>
                                <th scope="col">Create Date</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map(ticket => (
                                <tr key={ticket.ticket_id}>
                                    <td scope="row">{ticket.ticket_id}</td>
                                    <td>
                                        <Link className="dropdown-item" to={`/${props.redirect}/${ticket.id}`}>
                                            <img
                                                src={ticket.requester_image}
                                                alt={ticket.requester_name}
                                                width={30}
                                                height={30}
                                            /> {ticket.requester_name}
                                        </Link>
                                    </td>
                                    <td><Link className="dropdown-item" to={`/${props.redirect}/${ticket.id}`}>{ticket.subject}</Link></td>
                                    <td className={(ticket.status === 'open' ? 'text-success' : (ticket.status === 'closed' ? 'text-danger' : 'text-warning'))}>
                                        {/* {ticket.status === 'open' ? '&#x25CB;' : (ticket.status === 'closed' ? '&#x25CF;' : '&#x25CE;')} */}
                                        {ticket.status}
                                    </td>
                                    <td>{ticket.priority}</td>
                                    <td>{ticket.assignee ? ticket.assignee : 'No Assignee'}</td>
                                    <td>{ticket.data_needed}</td>
                                    {/* <td className="text-center">&#8942;</td> */}
                                    <td className="text-center">

                                        <Dropdown>
                                            <Dropdown.Toggle variant="secundary" id="dropdown-basic">
                                                &#8942;
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Link className="dropdown-item" to={`/${props.redirect}/${ticket.id}`}>
                                                    Open Ticket
                                                </Link>
                                                <Dropdown.Item style={{ display: user_permission === 'END_USER' ? 'none' : '' }} onClick={() => handleDeleteTicket(ticket.id)}>Remove Ticket</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>


                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
    )
}